import React from 'react';

import SolutionsApp from './Solutions';

const App = () => (
  <>
    <SolutionsApp />
  </>
);

export default App;
